<app-navbar-style-one></app-navbar-style-one>


<div class="cta_section">
    <img src="assets/img/edited_febevent.png" alt="" style="width:100%;">
    <!-- <div class="button_div"> -->
    <!-- <div class="option-item"> -->
    <!-- <span (click)="bookEvent()"><i class="bx bx-pencil" style="padding-right: 5px;"></i>Book Event</span> -->
    <!-- </div> -->
    <!-- </div> -->
</div>

<div class="earnings-box">
    <div class="container booking-form">
        <form #uplineSubmit="ngForm" [formGroup]="uplineForm" (ngSubmit)="submitNext(uplineSubmit)">
            <div class="row">
                <div class="form-group col-lg-12">
                    <label for="">Upline</label>
                    <select name="" id="" class="form-control form-select"
                        [class.is-invalid]="uplineSubmit.submitted && uplineForm.get('Cond1').invalid"
                        (change)="getDownline()" formControlName="Cond1">
                        <option value="" disabled>Select Upline</option>
                        <ng-container *ngFor="let upline of uplineList">
                            <option [value]="upline.id">{{upline.value}}</option>
                        </ng-container>
                    </select>
                    <div class="invalid-feedback">Please Select Upline</div>
                </div>
                <div class="form-group col-lg-12">
                    <label for="">Downline</label>
                    <select name="" id="" class="form-control form-select"
                        [class.is-invalid]="uplineSubmit.submitted && uplineForm.get('downLineName').invalid"
                        formControlName="downLineName">
                        <option value="" disabled>Select Downline</option>
                        <ng-container *ngFor="let upline of downlineList">
                            <option [value]="upline.id">{{upline.value}}</option>
                        </ng-container>
                    </select>
                    <div class="invalid-feedback">Please Select Downline</div>
                </div>
            </div>
            <button type="submit">Proceed</button>
        </form>
        <p (click)="displayModal = true" style="text-decoration: underline;cursor: pointer;">Terms & Condition</p>
    </div>
</div>
<!-- 
<div class="strip">
    
</div> -->

<!-- <p-dialog header="Terms & Condition" [breakpoints]="{'960px': '75vw'}" [style]="{width: '50vw'}" [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}"
    [draggable]="false" [resizable]="false">
        <ul>
            <li style="margin-bottom: 10px;">Check-in : 15 April 2023</li>
            <li>Check-out : 20 April 2023</li>
            <li>Rates are per person based on 5 Nights accommodation on double / twin sharing basis.</li>
            <li>Daily Buffet Breakfast in Hotel</li>
            <li>2 Water Bottel per day per room</li>
            <li>Hotel Check-in Time :- 02:00 pm</li>
            <li>Hotel Check-out Time :- 12:00 pm</li>
            <li>4 days (16, 17, 18 & 19 April) transfer by coaches from hotel to venue.</li>
            <li>Rates not include Credit Card, Debit Card, Net Banking & UPI Merchant convenience fees.</li>
            <li>Rates are fully non-refundable.</li>
            <li>Dates are non-changable</li>
            <li>Specific Hotel will be allotted by your upline.</li>
            <li>Full payment must be cleared before check-in</li>
            <li>Hotel Name & Details will be shared, one week prior to the event.</li>
            <li>Any Loss or Damage in hotel premises penalty will be pay to the hotel directly</li>
            <li>Any additional services which is not complimentary such as Extra internet usage, extra water bottles, room services, Mini Bar etc. will be paid directly to Hotel</li>
            <li>Smoking is prohibited in all hotels... if hotel found someone guilty he/she need to pay penalty.</li>
            <li>If you make a booking and money is deducted, you should wait for 24 - 48hrs and if you do not receive email confirmation till then, please email us support@adorntourism.com
                mentioning the following details: -</li>
                <br>
                <li style="list-style: none;">IR ID Used for booking</li>
                <li style="list-style: none;">Name for whom Booking was done /</li>
                <li style="list-style: none;">Name of ISB Upline/</li>
                <li style="list-style: none;">Date of Booking/</li>
                <li style="list-style: none;">Time of Booking/</li>
                <li style="list-style: none;">Amount deducted</li>
        </ul>
        <ng-template pTemplate="footer">
        </ng-template>
</p-dialog> -->

<p-dialog header="Terms & Condition" [breakpoints]="{'960px': '75vw'}" [style]="{width: '50vw'}"
    [(visible)]="displayModal" [modal]="true" [style]="{width: '50vw'}" [draggable]="false" [resizable]="false">
    <ul>
        <li>Check-in Date : 17 April 24</li>
        <li>Check-out Date : 23 April 24</li>
        <li>Rates are per person based on 6 Nights accommodation on a double / twin sharing basis.</li>
        <li>Daily Buffet Breakfast in Hotel</li>
        <li>2 Water Bottel per day per room</li>
        <li>Hotel Check-in Time :- 02:00 pm</li>
        <li>Hotel Check-out Time :- 12:00 pm</li>
        <li>5 days (18, 19, 20, 21 & 22 April) transfer by coaches from hotel to venue.</li>

        <!-- <li>Check-in Date : 07 September 2023</li>
        <li>Check-out Date : 13 September 2023</li>
        <li>Rates are per person based on 6 Nights accommodation on a double / twin sharing basis.</li>
        <li>Daily Buffet Breakfast in Hotel</li>
        <li>2 Water Bottel per day per room</li>
        <li>Hotel Check-in Time :- 02:00 pm</li>
        <li>Hotel Check-out Time :- 12:00 pm</li>
        <li>5 days (8, 9, 10, 11 & 12 September) transfer by coaches from hotel to venue.</li> -->
        <li>Rates not include Credit Card, Debit Card, Net Banking & UPI Merchant convenience fees.</li>
        <li>Rates are fully non-refundable.</li>
        <li>Dates are non-changable</li>
        <li>Early Check-in & Late Check-out are Subject to rooms availability with below condition:<br>
            Early Check-in between 6am - 12pm will be charged 50% from room rate.<br>
            Early Check-in before 6am on arrival date will be charged 100% from room rate.<br>
            Late Check-out until 6pm will be charged 50% from room rate.<br>
            Late Check-out after 6pm will be charge 100% from room rate<br>
        </li>
        <li>Specific Hotel will be allotted by your upline.</li>
        <li>Full payment must be cleared before check-in</li>
        <li>Hotel Name & Details will be shared, one week prior to the event.</li>
        <li>Any Loss or Damage in hotel premises penalty will be pay to the hotel directly</li>
        <li>Any additional services which is not complimentary such as Extra internet usage, extra water bottles, room
            services, Mini Bar etc. will be paid directly to Hotel</li>
        <li>Smoking is prohibited in all hotels... if hotel found someone guilty he/she need to pay penalty.</li>
        <li>
            If you make a booking and money is deducted, you should wait for 24 - 48hrs and if you do not receive email
            confirmation till then, please email us support@adorntourism.com
            mentioning the following details: -
            <ul>
                <li style="list-style: none;">IR ID Used for booking</li>
                <li style="list-style: none;">Name for whom Booking was done /</li>
                <li style="list-style: none;">Name of ISB Upline/</li>
                <li style="list-style: none;">Date of Booking/</li>
                <li style="list-style: none;">Time of Booking/</li>
                <li style="list-style: none;">Amount deducted</li>
            </ul>
        </li>

    </ul>
    <ng-template pTemplate="footer">
        <!-- <p-button icon="pi pi-check" (click)="displayModal=false" label="Ok" styleClass="p-button-text"></p-button> -->
    </ng-template>
</p-dialog>